<template>
  <div class="packaging">
    <v-list class="px-4">
      <v-row>
        <v-icon>mdi-cloud-upload</v-icon>
        <v-card-title>Packing Information</v-card-title>
      </v-row>
      <v-row>
        <v-col>
          <v-list-item-title><b>Packing Method</b></v-list-item-title>
          <p>{{packageDeatils.packingMethod}}</p>
        </v-col>
        <v-col v-if="packageDeatils.packingMethod != 'envelope'">
          <v-list-item-title><b>Weight</b></v-list-item-title>
          <p>{{packageDeatils.width }}</p>
        </v-col>
        <v-col v-if="packageDeatils.packingMethod != 'envelope'">
          <v-list-item-title><b>Length</b></v-list-item-title>
          <p>{{ packageDeatils.length }}</p>
        </v-col>
        <v-col v-if="packageDeatils.packingMethod != 'envelope'">
          <v-list-item-title><b>Height</b></v-list-item-title>
          <p>{{ packageDeatils.height}}</p>
        </v-col>
      </v-row>
    </v-list>
  </div>
</template>

<script>

export default {

  name: "packaginginfo",
  props: ["packageDeatils"],
  data: () => ({
  }),
};
</script>

<style>
</style>