<template>
  <div class="addNewOrderss-">
    <div id="single">
      <div class="left">
        <!-- {{shipment.itemInfo}} -->
        <paymentDetailsCard
          v-if="shipment.currentStatusCode > 6"
          :paymentDetails="shipment.paymentDetails"
        />
        <paymentCard
          v-if="shipment.currentStatusCode == 6 && shipment.uid == currentUid"
          :preferedPayMode="shipment.preferedPayMode"
          :orderId="shipment.orderId"
          :docId="shipment.id"
          :shipmentId="shipment.id"
          :shipmentNo="shipment.shipmentNo"
          :paymentProofs="shipment.paymentProofs"
          :paymentProofSubmitted="shipment.paymentProofSubmitted"
          :rate="shipment.priceDetails.total"
          :fName="shipment.user.name"
          lName="User"
          :email="shipment.user.email"
          :phone="shipment.user.phone.number"
        />
        <statusOrder
          :statuses="shipment.statuses"
          :orderId="shipment.orderId"
          :shipment="shipment.shipmentNo"
          :names="shipment.user.name"
          :currentStatus="shipment.currentStatus"
          :invoiceUrl="invoiceUrl"
          :trackingId="trackingId"
        />
      </div>
      <v-card class="right white pa-4" id="receiverInfo">
        <recieversInfo :recieverdata="shipment.receiverInfo" />
        <iteminfo :items="shipment.itemInfo" />
        <packagingInfo :packageDeatils="shipment.packageInfo" />
        <!-- <SingleOrderShipmentInfocommonFile  /> -->
        <!-- <SingleOrderShipmentInfoAllItems /> -->
      </v-card>
    </div>
  </div>
</template>

<script>
import { orderService } from "../../../services";
// import addNewOrders from "../../../components/addNew";
// import checkout from "../../../components/addNew/checkout.vue";
import { auth } from "../../../firebase";
import iteminfo from "@/components/singleOrder/shipmentInfo/itemInfo.vue";
import recieversInfo from "@/components/singleOrder/shipmentInfo/recieversInfo.vue";
import packagingInfo from "@/components/singleOrder/shipmentInfo/packagingInfo.vue";
import paymentCard from "@/components/singleOrder/paymentCard.vue";
import paymentDetailsCard from "@/components/singleOrder/paymentDetailsCard.vue";
import statusOrder from "@/components/singleOrder/statusOrder.vue";

export default {
  name: "AddNewOrder",
  components: {
    // addNewOrders,
    iteminfo,
    recieversInfo,
    packagingInfo,
    paymentCard,
    statusOrder,
    paymentDetailsCard,
    // checkout,
  },

  created() {
    this.init();
  },

  data() {
    return {
      //auth
      currentUid: null,

      //shipment
      shipment: null,

      invoiceUrl: null,
      trackingId: null,
    };
  },
  methods: {
    async init() {
      console.log("orderdfghjk");
      console.log(this.$route.params.id);
      this.shipment = await orderService.getSingleOrder(this.$route.params.id);
      console.log(this.shipment);

      //set URL in available
      if (this.shipment.invoiceDetails)
        this.invoiceUrl = this.shipment.invoiceDetails.url;

      //set Tracking ID if available
      if (this.shipment.priceDetails)
        if (this.shipment.priceDetails.trackingId)
          this.trackingId = this.shipment.priceDetails.trackingId;

      this.currentUid = auth.currentUser.uid;
    },
  },

  getTrackingId() {
    return null;
  },
};
</script>

<style lang="scss" scoped>
#receiverInfo {
  background-color: #fff;
  padding: 20px 40px;
  border-radius: 10px;
  box-shadow: 0px 6px 12px rgba(24, 39, 75, 0.12),
    0px 8px 24px rgba(24, 39, 75, 0.08);
}

#single {
  display: flex;
  justify-content: space-evenly;
  margin-top: 30px;
  margin-bottom: 30px;
}
.left {
  margin-top: 10px;

  /* display: flex;
    justify-content:space-evenly;
    flex-direction: column;
  width:50%; */
}
.right {
  width: 40%;
}
@media (max-width: 768px) {
  #single {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    margin-top: -40px;
  }
  .right {
    width: 100%;
  }
  .left {
    width: 100%;
  }
}
</style>
