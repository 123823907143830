<template>
  <div class="item">
    <v-row class="ml-2">
      <v-icon>mdi-domain</v-icon>
      <v-card-title>Item Information</v-card-title>
    </v-row>
    <v-list class="px-4">
      <div
        class="itemInfo"
        v-for="(item, index) in items"
        :key="index"
        :item="item"
      >
        <v-row>
          <v-col>
            <v-list-item-title><b>Item Description</b></v-list-item-title>
            <p>{{ item.itemDescription }}</p>
          </v-col>
          <v-col>
            <v-list-item-title><b>Purpose</b></v-list-item-title>
            <p>{{ item.purpose }}</p>
          </v-col>
          <v-col>
            <v-list-item-title><b>Value</b></v-list-item-title>
            <p>{{ item.value }}</p>
          </v-col>
          <v-col>
            <v-list-item-title><b>Weight</b></v-list-item-title>
            <p>{{ item.weight }}</p>
          </v-col>
        </v-row>
      </div>
    </v-list>
  </div>
</template>

<script>
// import {mapGetters} from "vuex";
export default {
  name: "iteminfo",
  props: ["items"],
  // computed:{
  //   ...mapGetters(["item"])
  // }
};
</script>

<style scoped>
.itemInfo {
  background-color: #f3f3f3;
  border-radius: 7px;
  padding: 10px 10px;
  margin-bottom: 10px;
}
</style>