<template>
  <v-card class="paymentCard" :loading="loading" :disabled="disabled">
    <v-card-title>Payment Details</v-card-title>
    <v-card-content>
      <ul>
        <li v-if="paymentDetails.paymentInformation">
          <b>Payment Information </b> {{ paymentDetails.paymentInformation }}
        </li>
        <li v-if="paymentDetails.timestamp">
          <b>Time </b> {{ getDate(paymentDetails.timestamp) }}
        </li>
        <li v-if="paymentDetails.remarks">
          <b>Remarks </b> {{ paymentDetails.remarks }}
        </li>
        <li v-if="paymentDetails.approvedBy">
          <b>Approved By </b> {{ paymentDetails.approvedBy }}
        </li>
        <li v-if="paymentDetails.referenceNumber">
          <b>Reference Number </b> {{ paymentDetails.referenceNumber }}
        </li>
        <li v-if="paymentDetails.amountTransferred">
          <b>Amount Transferred </b> {{ paymentDetails.amountTransferred }} LKR
        </li>
      </ul>
    </v-card-content>
  </v-card>
</template>

<script>
import moment from "moment";
export default {
  name: "paymentDetailsCard",
  props: ["paymentDetails"],
  methods: {
    getDate(timeStamp) {
      const testTime = new Date(timeStamp.toDate());
      return moment(testTime).format("MMMM Do YYYY, h:mm:ss a");
    },
  },
};
</script>

<style scoped>
.paymentCard {
  background-color: #fff;
  padding: 0px 40px 20px 40px;
  border-radius: 10px;
  box-shadow: 0px 6px 12px rgba(24, 39, 75, 0.12),
    0px 8px 24px rgba(24, 39, 75, 0.08);
  margin-bottom: 20px;
}
ul {
  width: 100%;
  list-style-type: none;
}
</style>