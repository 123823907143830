<template>
  <div>
    <div class="status-wrap">
      <div class="Status">
        <!-- <v-card width="800" height="600" class="white rounded-xl" > -->
        <div class="left" mx-2>
          <v-card-title>hi {{ names }}!</v-card-title>
          <v-card-subtitle>Your Order is in transit</v-card-subtitle>
          <v-container>
            <v-row class="pl-3">
              <div class="l_1 mr-5">
                <h4>Order Id</h4>
                <p>{{ orderId }}</p>
              </div>
              <div class="r_1">
                <h4>Shipping No</h4>
                <p>{{ shipment }}</p>
              </div>
            </v-row>

            <h4>Status</h4>

            <v-chip rounded :color="getColor(currentStatus.color)" class="pa-4">
              {{ currentStatus.title }}
            </v-chip>
            <p>{{ currentStatus.description }}</p>

            <a
              style="font-size: 18px"
              target="_blank"
              v-if="currentStatus.id >= 6"
              :href="invoiceUrl"
              >View Invoice</a
            >

            <h4 v-if="currentStatus.id >= 6">Tracking ID</h4>
            <p v-if="currentStatus.id >= 6">
              {{ trackingId }}
            </p>
          </v-container>
        </div>

        <!-- right -->
        <div class="right">
          <v-timeline dense>
            <v-timeline-item
              small
              color="grey"
              v-for="status in statuses"
              :key="status.id"
            >
              <v-col>
                <strong>{{ status.title }}</strong>
                <div class="text-caption">
                  <!-- {{ status.updatedAt }} -->
                  {{ getDate(status.updatedAt) }}
                  ({{ getTime(status.updatedAt) }})
                </div>
              </v-col>
            </v-timeline-item>
          </v-timeline>
        </div>
      </div>
      <!-- <div class="btn">
        <v-col>
          <div class="my-2 cut">
            <v-btn color="#FF5530" dark text>
              <v-icon class="mr-2">mdi-minus-circle</v-icon>
              Cancel Shipment</v-btn
            >
          </div>
        </v-col>
      </div> -->
    </div>

    <!-- </v-card> -->
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "recieverinfo",
  props: [
    "statuses",
    "orderId",
    "shipment",
    "names",
    "currentStatus",
    "invoiceUrl",
    "trackingId",
  ],

  methods: {
    getColor(colorCode) {
      return `#${colorCode}`;
    },
    getDate(date) {
      const testDate = new Date(date.toDate());
      // console.log(date, testDate);
      return moment(testDate).format("D/MM/YYYY");
    },
    getTime(time) {
      const testTime = new Date(time.toDate());
      // console.log(time, testTime);
      return moment(testTime).format("h:mm A");
    },
  },
};
</script>

<style lang="scss" scoped>
.Status {
  display: flex;

  // border:2px solid;
  // border-radius: 10px;
  height: 50%;
}
.status-wrap {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 20px 40px;
  border-radius: 10px;
  box-shadow: 0px 6px 12px rgba(24, 39, 75, 0.12),
    0px 8px 24px rgba(24, 39, 75, 0.08);
}
.btn {
  display: flex;
}
// .left{
//     display: flex;
// }
// .right{
//     display: flex;
// }
.msg {
  margin-top: 20px;
}
.box {
  border: 2px solid #710000;
  margin-top: 20px;
  background-color: #ffe5e5;
  width: 40%;
  border-radius: 10px;
  p {
    color: #710000;
    width: 60%;
  }
}
h4 {
  color: "#FFFFFF";
}

@media (max-width: 650px) {
  .Status {
    display: flex;
    flex-direction: column;
    // text-align: center;
    // align-items: center;
  }
  .btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

// @media (width:786px) {
//   .btn{
//     display: flex;
//     flex-direction:row;
// }
// }
</style>
