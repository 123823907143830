<template>
  <div class="reciverinfo">
    <v-list class="px-4">
      <v-row>
        <!-- name -->
        <v-col>
          <v-list-item-title><b>Customer name</b></v-list-item-title>
          <p v-if="recieverdata.name">
            {{ recieverdata.name }}
            <span v-if="recieverdata.lastName"
              >{{ recieverdata.lastName }}
            </span>
          </p>

          <p v-else>N/A</p>
        </v-col>
        <v-col>
          <v-list-item-title><b>Email</b></v-list-item-title>
          <p v-if="recieverdata.email != null">{{ recieverdata.email }}</p>
          <p v-else>N/A</p>
        </v-col>
      </v-row>
      <v-row>
        <!-- phone -->
        <v-col>
          <v-list-item-title><b>Customer Phone No</b></v-list-item-title>
          <p v-if="recieverdata.mobile">{{ recieverdata.mobile }}</p>
          <p v-else>N/A</p>
        </v-col>
        <!-- id -->
        <v-col>
          <v-list-item-title><b>Company Name</b></v-list-item-title>
          <p v-if="recieverdata.company">{{ recieverdata.company }}</p>
          <p v-else>N/A</p>
        </v-col>
        <v-col>
          <v-list-item-title><b>Courier serviece</b></v-list-item-title>
          <p v-if="recieverdata.courierService.title">
            {{ recieverdata.courierService.title }}
          </p>
          <p v-else>N/A</p>
        </v-col>
        <v-col>
          <v-list-item-title><b>VAT/VAX</b></v-list-item-title>
          <p v-if="recieverdata.vatOrTax != null">
            {{ recieverdata.vatOrTax }}
          </p>
          <p v-else>N/A</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-list-item-title><b>Address Line-1</b></v-list-item-title>
          <p v-if="recieverdata.address1">{{ recieverdata.address1 }}</p>
          <p v-else>N/A</p>
        </v-col>
        <v-col>
          <v-list-item-title><b>Address Line-2</b></v-list-item-title>
          <p v-if="recieverdata.address2">{{ recieverdata.address2 }}</p>
          <p v-else>N/A</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-list-item-title><b>Address Line-3</b></v-list-item-title>
          <p v-if="recieverdata.address3 != null">
            {{ recieverdata.address3 }}
          </p>
          <p v-else>N/A</p>
        </v-col>
        <v-col>
          <v-list-item-title><b>Address Line-4</b></v-list-item-title>
          <p v-if="recieverdata.address4 != null">
            {{ recieverdata.address4 }}
          </p>
          <p v-else>N/A</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-list-item-title><b>Country</b></v-list-item-title>
          <p v-if="recieverdata.country.name">
            {{ recieverdata.country.name }}
          </p>
          <p v-else>N/A</p>
        </v-col>
        <v-col>
          <v-list-item-title><b>Zip Code</b></v-list-item-title>
          <p v-if="recieverdata.zipcode">{{ recieverdata.zipcode }}</p>
          <p v-else>N/A</p>
        </v-col>
      </v-row>
    </v-list>
  </div>
</template>

<script>
export default {
  name: "recieverInfo",
  props: ["recieverdata"],
};
</script>

<style></style>
